<template>
  <v-container class="mt-4">
    <snackbar :attr="snackbar" />
    <v-row>
      <v-col cols="12">
        <v-dialog v-model="userDialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <vue-fontawesome :icon="['fas', 'user-plus']" class="mr-1" />Add
              User
            </v-btn>
          </template>
          <v-card>
            <v-toolbar elevation="0">
              <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="closeUserDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="user.firstName"
                      required
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      hide-details="auto"
                    ></v-text-field>
                    <!-- <v-text-field label="Legal first name*" required></v-text-field> -->
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="user.lastName"
                      :rules="rules"
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="user.email"
                      :rules="emailRules"
                      id="email"
                      name="email"
                      label="Email"
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" v-if="isDev">
                    <v-checkbox
                      v-model="user.isActive"
                      label="Active"
                      id="isActive"
                      name="isActive"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="3" lg="3" v-if="isDev">
                    <v-checkbox
                      v-model="user.isAdmin"
                      label="Admin"
                      id="isAdmin"
                      name="isAdmin"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6" lg="3" v-if="isDev">
                    <v-checkbox
                      v-model="user.isDev"
                      label="Dev"
                      id="isDev"
                      name="isDev"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="user.agencyObject"
                      :items="agencies"
                      item-value="id"
                      item-text="name"
                      return-object
                      label="Agency"
                      hide-details="auto"
                      :rules="rules"
                    >
                    </v-select>
                    <!-- <v-select
                      v-model="user.agencyObject"
                      :items="agencies"
                      item-value="id"
                      item-text="name"
                      return-object
                      label="Agency"
                      hide-details="auto"
                      :rules="rules"
                    >
                    </v-select> -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeUserDialog"> Close </v-btn>
              <v-btn
                elevation="0"
                color="primary"
                class="mr-4"
                @click="saveUser"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="12" sm="6" md="6" lg="4" class="mt-2">
        <v-text-field
          filled
          rounded
          dense
          single-line
          clearable
          v-model="search"
          label="Filter"
          hide-details
          ><vue-fontawesome
            :icon="['far', 'search']"
            color="grey"
            slot="prepend-inner"
            class="mt-1 mr-1"
        /></v-text-field>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :headers="headers"
          :items="users"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-dialog v-model="userDialogDelete" width="500">
              <v-card>
                <v-card-title class="headline">
                  <span class="headline">
                    <vue-fontawesome icon="exclamation-triangle" class="mr-2" />
                    Delete User?</span
                  >
                </v-card-title>
                <v-card-text>
                  This user will be permenantly deleted.
                  <div class="text--disabled body-2">{{ userId }}</div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="userDialogDelete = false">
                    No
                  </v-btn>
                  <v-btn text @click="deleteUserConfirm"> Yes </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.fullName="{ item }">
            <b>{{ item.firstName + ' ' + item.lastName }}</b>
            <!-- <b>{{ item.fullName }}</b> -->
          </template>
          <!-- <template v-slot:item.name="{ item }">
            {{ item.firstName + ' ' + item.lastName }}
          </template> -->
          <!-- <template v-slot:item.agency="{ item }">
            {{ item._id }}
          </template> -->
          <!-- <template v-slot:item.agency="{ item }">
            {{ item.name }}
          </template> -->
          <template v-slot:item.actions="{ item }">
            <!-- <router-link :to="{ path: '/user/' + item.id }">
              <v-btn icon small>
                <vue-fontawesome
                  small
                  :icon="['far', 'chevron-right']"
                  class="mr-1"
                />
              </v-btn>
            </router-link> -->
            <v-btn icon @click="updateStatus(item)">
              <vue-fontawesome
                :class="
                  item.isActive ? 'green--text text--darken-4' : 'grey--text'
                "
                :icon="item.isActive ? ['fas', 'user'] : ['fas', 'user-slash']"
              />
            </v-btn>
            <v-btn icon small @click="editUser(item, item.id)" class="mr-1">
              <vue-fontawesome small :icon="['fas', 'pencil-alt']" />
            </v-btn>
            <v-btn icon small @click="sendInvite(item)" class="mr-1">
              <vue-fontawesome small :icon="['fas', 'envelope']" />
            </v-btn>
            <v-btn icon small @click="deleteUser(item, item.id)">
              <vue-fontawesome :icon="['fas', 'trash']" />
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AgencyService from '@/services/AgencyService'
import UserService from '@/services/UserService'
import Snackbar from '@/components/Snackbar.vue'

export default {
  name: 'Users',
  components: {
    Snackbar
  },
  data() {
    return {
      snackbar: {
        model: false,
        color: '',
        message: '',
        icon: ['fas', 'exclamation-triangle'],
        timeout: 3000
      },
      search: '',
      agencies: [],
      agencyList: false,
      dialog: false,
      userDialog: false,
      userDialogDelete: false,
      userIndex: -1,
      userId: '',
      users: [],
      user: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        // isActive: true,
        isAdmin: false,
        isDev: false,
        agency: {
          id: '',
          name: ''
        },
        agencyObject: {}
      },
      userDefault: {
        // id: '',
        firstName: '',
        lastName: '',
        email: '',
        isActive: false,
        isAdmin: false,
        isDev: false,
        agency: {
          id: '',
          name: ''
        },
        agencyObject: {}
      },
      rules: [v => !!v || 'This is required'],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      genderOptions: ['Male', 'Female'],
      headers: [
        {
          text: 'Name',
          align: 'start',
          filterable: true,
          value: 'fullName'
        },
        {
          text: 'Email',
          align: 'start',
          filterable: true,
          value: 'email'
        },
        // {
        //   text: 'Id',
        //   align: 'start',
        //   filterable: true,
        //   value: 'id'
        // },
        // {
        //   text: 'Agency Id',
        //   align: 'start',
        //   filterable: true,
        //   value: 'agency._id'
        // },
        {
          text: 'Agency Name',
          align: 'start',
          filterable: true,
          value: 'agency.name'
        },
        // {
        //   text: 'ID',
        //   align: 'start',
        //   filterable: true,
        //   value: 'id'
        // },
        {
          text: 'Actions',
          align: 'end',
          value: 'actions',
          sortable: false
        }
      ]
      // activities: activities
    }
  },
  watch: {
    userDialog(val) {
      // console.log(this.agencies)
      // alert('edit?')
      // this.isEdit
      val || this.closeUserDialog()
      if (!this.agencyList) {
        AgencyService.getAll()
          .then(response => {
            this.agencies = response.data
            this.agencyList = true
            // console.log(response.data)
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    userDialogDelete(val) {
      val || this.closeUserDelete()
    }
  },
  created() {
    UserService.getAll()
      .then(response => {
        // let token = localStorage.getItem("jwt");
        // let decoded = VueJwtDecode.decode(token);
        // this.user = decoded;
        this.users = response.data
        // console.log(response.data)
      })
      .catch(error => {
        console.log('We had an error: ' + error)
      })
  },
  mounted() {},
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    formTitle() {
      return this.userIndex === -1 ? 'New User' : 'Edit User'
    },
    isDev() {
      return this.$store.state.user.isDev || false
    },
    isEdit() {
      return this.userIndex === -1 ? false : true
    }
  },
  methods: {
    editUser(item, id) {
      // alert(id)
      this.userIndex = this.users.indexOf(item)
      this.user = Object.assign({}, item)
      this.user.agencyObject = { id: this.user.agency._id }
      this.userId = id
      this.userDialog = true
      // console.log(this.user)
    },
    deleteUser(item, id) {
      // alert(id)
      this.userIndex = this.users.indexOf(item)
      this.user = Object.assign({}, item)
      this.userId = id
      this.userDialogDelete = true
    },
    deleteUserConfirm() {
      const id = this.userId
      // alert(id)
      UserService.delete(id)
        .then(response => {
          console.log(response)
          this.users.splice(this.userIndex, 1)
          this.closeUserDelete()
          this.snackbar.model = true
          this.snackbar.message = 'User deleted'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
        })
        .catch(e => {
          this.snackbar.model = true
          this.snackbar.message = 'User not deleted'
          this.snackbar.color = 'red'
          this.snackbar.icon = ['fas', 'exclamation-triangle']
          console.log(e)
        })
    },
    async saveUser() {
      const data = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        email: this.user.email,
        isActive: this.user.isActive,
        isAdmin: this.user.isAdmin,
        isDev: this.user.isDev,
        agency: this.user.agencyObject.id
      }
      if (this.userIndex > -1) {
        // console.log(this.user.agencyObject.id)
        this.user.agency._id = this.user.agencyObject.id
        this.user.agency.name = this.user.agencyObject.name
        // alert('update')
        Object.assign(this.users[this.userIndex], this.user)
        UserService.update(this.user.id, data)
          .then(() => {
            this.closeUserDialog()
            this.snackbar.model = true
            this.snackbar.message = 'User updated'
            this.snackbar.color = 'green'
            this.snackbar.icon = ['far', 'check']
            // this.successMessage = 'User updated'
            // this.resultAlert = true
            // console.log('Created new item successfully!' + id)
            // this.submitted = true
          })
          .catch(e => {
            this.snackbar.model = true
            this.snackbar.message = 'User error'
            this.snackbar.color = 'red'
            this.snackbar.icon = ['fas', 'exclamation-triangle']
            console.log(e)
          })
      } else {
        // const data = {
        //   firstName: this.user.firstName,
        //   lastName: this.user.lastName,
        //   gender: this.user.gender,
        //   sid: this.user.sid,
        //   race: {
        //     label: this.user.race.label,
        //     other: this.user.race.other
        //   }
        // }
        this.saveNewUser(data)
      }
    },
    saveNewUser(userData) {
      UserService.create(userData)
        .then(response => {
          const newId = response.data.id
          // console.log(newId)
          const newUser = Object.assign(this.user)
          newUser.id = newId
          // const newUser = Object.assign({ id: newId }, this.user)
          // console.log(newUser)
          this.users.push(newUser)
          this.closeUserDialog()
          this.snackbar.model = true
          this.snackbar.message = 'User created'
          this.snackbar.color = 'green'
          this.snackbar.icon = ['far', 'check']
          // console.log('Created new user successfully! ' + response.data.id)
          // this.submitted = true
        })
        .catch(err => {
          console.log(err)
        })
    },
    async sendInvite(item) {
      // console.log(item)
      // return false
      try {
        const data = {
          email: item.email
        }
        const requestResult = await UserService.requestReset(data)
        if (!requestResult) {
          return
        }
        this.snackbar.model = true
        this.snackbar.message = 'Passord reset sent.'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = err.response.data.msg
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
      }
    },
    async updateStatus(item) {
      item.isActive = !item.isActive
      try {
        const data = {
          isActive: item.isActive
        }
        const requestResult = await UserService.updateStatus(item.id, data)
        if (!requestResult) {
          return
        }
        this.snackbar.model = true
        this.snackbar.message = 'Status updated.'
        this.snackbar.color = 'green'
        this.snackbar.icon = ['far', 'check']
      } catch (err) {
        this.snackbar.model = true
        this.snackbar.message = err.response.data.msg
        this.snackbar.color = 'red'
        this.snackbar.icon = ['fas', 'exclamation-triangle']
      }
    },
    closeUserDialog() {
      this.userDialog = false
      this.$nextTick(() => {
        this.user = Object.assign({}, this.userDefault)
        this.userIndex = -1
      })
    },
    closeUserDelete() {
      this.userDialogDelete = false
      this.$nextTick(() => {
        this.user = Object.assign({}, this.userDefault)
        this.userIndex = -1
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
